import regions from '@/data/regions.json';
import themes from '@/data/themes.json';
import countries from '@/data/countries.json';
import type { SourceType } from './composables/types/knowledgeService';


// Mixpanel
export enum DocumentChatTrackEvents {
  CHAT_CREATED = 'document_chat_created',
  FULLSCREEN_OPENED = 'document_chat_fullscreen_opened',
  SETTINGS_OPENED = 'document_chat_settings_opened',
  DRAG_CHART = 'document_chat_drag_chart',
  DRAG_MARKDOWN = 'document_chat_drag_markdown'
}

export enum DocumentSourceTrackEvents {
  DATASET_VIEWED = 'document_source_dataset_viewed',
  DATASET_MODIFIED = 'document_source_dataset_modified',
}

export enum BillingTrackEvents {
  VIEW_PLANS = 'billing_view_plans',
}

export enum DocumentTrackEvents {
  EXPORT_DOCUMENT = 'document_export_document',
  GENERAL_FEEDBACK = 'document_general_feedback',
}

export enum SearchTrackEvents {
  OPEN_CONTINUE_IN_DOCUMENT = 'search_open_continue_in_document_modal',
  CONTINUE_IN_DOCUMENT = 'search_continue_in_document_created',
}


// Identity Service
export interface User {
  id: string;
  name: string;
  email: string;
  firstName: string;
  lastName: string;
  onboarded: boolean;
  authProviders: AuthProvider[];
  onboardingQuestions?: OnboardingQuestion[];
  datawrapperApiKey?: string;
  organization: Organization;
  organizationRole: string;
  createdAt: string;
  intercomUserIdHmacHash: string | null;
  trialEndsAt: string | null;
  subscriptionCancelledAt: string | null;
  lastActiveAt: string | null;
  country: string;
}

export interface HomeSearchResult {
  id: string;
  type: string;
  createdAt: string;
  title: string;
  intro?: string;
}

export interface ChatSearchResult {
  valid: boolean;
  datasets: string[];
  improvedQuery: string;
  id: string;
  message: string;
  query?: string;
  ChatSearchDocumentSuggestions: ChatSearchDocumentSuggestion[];
}

export interface ChatSearchDocumentSuggestion {
  chatSearchResultId: string;
  content: string;
  createdAt: string;
  id: string;
  images: string[];
  sources: ChatSearchDocumentSuggestionSource[];
  subtext: string;
  title: string;
  updatedAt: string;
}

export interface ChatSearchDocumentSuggestionSource {
  id: number;
  title: string;
  url: string;
}

export interface Feature {
  id: string;
  name: string;
  peridicity: string;
  usageLimit: number;
}

export interface Feedback {
  questionCode: string;
  url: string;
  rating: string;
  comment: string;
  user: string;
}

export interface Organization {
  id: string;
  name: string;
  emailExtension: string;
  tier: {
    id: string,
    name: string;
    features: Feature[],
  },
  schematicId: string;
  stripeCustomerId: string;
}

export interface AuthProvider {
  type: AuthProviderType
}

export interface OnboardingQuestion {
  question: string;
  answer: string;
}

export enum AuthProviderType {
  LOCAL = 'LOCAL',
  GOOGLE = 'GOOGLE',
  MICROSOFT = 'MICROSOFT',
}

export interface AuthenticationStatus {
  authenticated: boolean,
  user: null | User;
}

// Core Service
export enum DatasetType {
  USER_DATA = "USER_DATA",
  OPEN_DATA = "OPEN_DATA",
}

export interface MetadataValue {
  type: string;
  missing: number;
  unique: number;
  categorical: boolean;
  categories: string[];
}

export enum DATA_SOURCE {
  CSV = 'CSV',
  GOOGLE_SHEET = 'GOOGLE_SHEET',
  PASTED_DATA = 'PASTED_DATA',
  EXCEL = 'EXCEL',
  WORKSPACE = 'WORKSPACE',
  POSTGRES = 'POSTGRES',
}
export interface UserMetadata {
  id: string;
  filename: string;
  source: DATA_SOURCE;
  context: string;
  details?: Record<string, any>;
  datasetId: string
}
export interface DatasetPermission {
  id?: string;
  shareSource: string;
  shareSourceId: string;
  userId: string | null;
  grantedByUserId: string;
  allowRead: boolean;
  allowWrite: boolean;
  allowShare: boolean;
  allowManage: boolean;
  isOwner: boolean;
  datasetId: string;
}



export enum DatasetStatus {
  CREATED = 'CREATED',
  IMPORT_START = 'IMPORT_START',
  IMPORT_SUCCESS = 'IMPORT_SUCCESS',
  IMPORT_ERROR = 'IMPORT_ERROR',
  METADATA_START = 'METADATA_START',
  METADATA_SUCCESS = 'METADATA_SUCCESS',
  METADATA_ERROR = 'METADATA_ERROR',
  INTERPRETING = 'INTERPRETING',
  INTERPRETED = 'INTERPRETED',
  ERROR_INTERPRETATION = 'ERROR_INTERPRETATION',
  ERROR_VALIDATION = 'ERROR_VALIDATION',
  ERROR = 'ERROR',
  UPLOAD_FAILED = 'UPLOAD_FAILED',
}

export interface CreateDatasetPropertyDto {
  readonly key: string;
  readonly value: string;
  readonly secure: boolean;
}

export interface CreateDatasetDto {
  readonly name: string;
  readonly type: DatasetType;
  readonly props: CreateDatasetPropertyDto[];
}

export interface ColumnInterpretation {
  id: string;
  column: string;
  description: string;
  type: string;
}

export interface DatasetProperty {
  id: string;
  key: string;
  secure: boolean;
  type: string;
  value: string;
}


export interface DatasetLifecycleEvent {
  id: string,
  status: DatasetStatus,
  createdAt: string | Date
  comment: string | null,
}

export interface OpenDatasetResultDto {
  datasets?: Array<{ dataset: Dataset, version: DatasetVersion, insightCount: number }>;
  duration: number;
  totalCount: number | null;
}

export interface DatasetDto {
  datasets: DatasetWithVersion[];
  folders: DatasetFolderDto[];
}
export interface DatasetFolderDto {
  folder: DatasetFolder;
  datasets: DatasetWithVersion[];
  children: DatasetFolderDto[];
}

export interface DatasetFolder {
  id: string;
  name: string;
  color: string;
  DatasetFolderId?: string | null;
  itemCount?: number;
  createdAt: Date | string;
  updatedAt: Date | string;
}

export interface DatasetWithVersion {
  dataset: Dataset;
  version: DatasetVersion;
}

export interface DatasetAutoRefreshConfig {
  datasetId: string;
  cron: string;
  id: string;
}

export interface Dataset {
  id: string,
  name: string,
  improvedTitle: string | null,
  type: DatasetType,
  shortDescription: string,
  createdAt: string | Date,
  updatedAt?: string | Date,
  providerMetadata: ProviderMetadata | null,
  userMetadata: UserMetadata | null,
  DatasetFolderId: string | null
  autoRefreshConfig: DatasetAutoRefreshConfig | null;
  sourceId: string;
}

export enum DatasetPropertyType {
  CONNECTION_PROPERTY = 'CONNECTION_PROPERTY',
  CSV_CREATE_TABLE_DDL = 'CSV_CREATE_TABLE_DDL',
  RECORD_COUNT = 'RECORD_COUNT',
  META_DTYPE = 'META_DTYPE',
  META_MISSING = 'META_MISSING',
  META_MIN_VALUE = 'META_MIN_VALUE',
  META_MAX_VALUE = 'META_MAX_VALUE',
  META_CATEGORICAL_VALUES = 'META_CATEGORICAL_VALUES',
  META_FULL = 'META_FULL',
  // DEPRECATED
  COLUMN_INTERPERATATION = 'COLUMN_INTERPERATATION',
  COLUMN_INTERPRETATION = 'COLUMN_INTERPRETATION',
}

export interface EnhancedInstructionType {
  enhancedInstruction: string;
  duration: number;
  success: boolean;
  error: string | null;
}


export interface Column {
  id: string;
  name: string;
  index: string | null;
  dataType: string;
  interpretedType: string;
  description: string;
  missingValues: number;
  uniqueValues: number;
  categorical: boolean;
  categories: string[];
  min: number;
  max: number;
  mean: number;
  median: number;
  datasetVersionId: string;
  context: string | null;
  unitOfMeasurement: string | null;
}

export interface DatasetVersion {
  id: string;
  name: string;
  datasetId: string;
  dataset: Dataset;
  publishedAt: Date;
  recordCount: number;
  filterMetadata: FilterMetadata;
  createdAt: string;
  error: boolean;
  ready: boolean
  sampleGrid: Array<any> | null;
  sourceVersionId: string;
}

export interface ProviderMetadata {
  id: string;
  provider: string;
  code: string;
  serie: object;
  creator: string;
  periodicity: string | null;
  description: string | null;
  unitOfMeasurement: string | null;
  dataset: Dataset;
  landingPageUrl: string | null;
  metadataUrl: string | null;
  name: string | null;
}

export enum INTERPRETED_TYPES {
  GEOSPATIAL = 'geospatial',
  TEMPORAL = 'temporal',
  CATEGORICAL = 'categorical',
  NUMERICAL = 'numerical',
  TEXTUAL = 'textual',
  URL = 'url',
  EMAIL = 'email',
  OTHER = 'other',
}

export interface UserPreference {
  workspaceType: 'steps' | 'chat';
  visualizationTheme: string;
}

export interface SavedPrompt {
  id: string;
  title: string;
  prompt: string;
  scope: string[];
  sharedWithOrganization: boolean;
}

export interface FilterMetadata {
  id: string;
  countries: string[];
  continents: string[];
  subregions: string[];
  firstDate: Date;
  lastDate: Date;
  themes: string[];
  lastUpdated: Date;
  updatedAt: Date;
  datasetVersion: DatasetVersion;
}

export enum ChatWorkspaceShareType {
  MEMBERS = 'MEMBERS',
  PUBLIC = 'PUBLIC',
}

export interface ChatWorkspaceDto {
  workspaces: Workspace[];
  folders: WorkspaceFolderDto[];
}

export interface DocumentListDto {
  documents: Document[];
  folders: WorkspaceFolderDto[];
}

export interface ChatWorkspace {
  id: string;
  name: string;
  lastAccessedAt: string;
  shared: ChatWorkspaceShareType | null;
  owner: string;
  language: string;
  analysisStyle: string;
  updateAvailable: boolean;
  autoRefresh: boolean;
}

export interface Workspace {
  id: string;
  name: string;
  createdAt: Date | string;
  updatedAt: Date | string;
  lastAccessedAt: Date | string;
  updateAvailable: boolean;
  shared: boolean;
  share?: WorkspaceShare,
  owner: string;
  WorkspaceFolderId?: string | null;
  previewImages?: string[];
}

export interface WorkspaceFolderDto {
  folder: WorkspaceFolder;
  workspaces: Workspace[];
  children: WorkspaceFolderDto[];
}

export interface DocumentFolderDto {
  folder: DocumentFolder;
  documents: Document[];
  children: DocumentFolderDto[];
}

export interface WorkspaceFolder {
  id: string;
  name: string;
  color: string;
  WorkspaceFolderId?: string | null;
  itemCount?: number;
  createdAt: Date | string;
  updatedAt: Date | string;
}

export interface WorkspaceRefreshTask {
  id: string;
  createdAt: string
  currentStep: string | null;
  started: boolean;
  completed: boolean;
  workspaceId: string;
}

export interface Step {
  id: string;
  index: number;
  type: STEP_TYPE;
  stepDetails: Record<string, any>;
  completed: boolean;
  error: boolean;
  duration: number;
  name: string;
  note: string;
  dataSourceType: DATA_SOURCE_TYPE;
  dataSourceId: string;
  recordCount: number;
  columns: ResultColumn[];
  attempts: StepAttempt[];
  createdAt: string;
}

export interface ResultColumn {
  name: string;
}


export interface StepAttempt {
  id: string;
  code: string;
  duration: number;
  step: string | null;
  reasoning: string;
  outputText: string;
  error: string | null;
  stepId: string;
}

export enum STEP_TYPE {
  FILTER = 'FILTER',
  SORT = 'SORT',
  VISUALIZE = 'VISUALIZE',
  INSTRUCTION = 'INSTRUCTION',
  RENAME_COLUMN = 'RENAME_COLUMN',
  REMOVE_COLUMN = 'REMOVE_COLUMN',
  ADD_COLUMN = 'ADD_COLUMN',
  QUICK_SUMMARY = 'QUICK_SUMMARY',
  RESHAPE = 'RESHAPE',
}

export enum DATA_SOURCE_TYPE {
  DATASET = 'DATASET',
  STEP = 'STEP',
}

export interface WorkspaceDataset {
  workspacId: string;
  datasetId: string;
  datasetVersionId: string;
  updateAvailable: boolean;
}

export interface GetDataOptions {
  limit: null | number,
  offset: null | number,
  order: null | OrderQuery
}

export interface OrderQuery {
  field: string,
  direction: string,
}


//Storage service

export enum FileSourceType {
  UPLOAD = 'UPLOAD',
  URL = 'URL',
}

export interface UploadFileDto {
  dataset: string
  version: string;
  url?: string;
  type: FileSourceType
}

export interface UpdateWorkspaceDto {
  name: string;
  lastAccessedAt: Date | string;
  datasetVersions: string[];
}


// instruction service
export interface CreateInstructionDto {
  instruction: string;
  workspaceId: string;
  parentInstructionId?: string;
}

export interface Instruction {
  id: string;
  reasoning: string;
  reasoningComplete: boolean;
  instructionText: string;
  parentId: string;
  children: Instruction[];
  workspace: string;
  createdAt: Date;
  result: InstructionResult;
  error: boolean;
  attempts: InstructionAttempt[];
}

export interface InstructionAttempt {
  id: string;
  model: string;
  executionEnvironment: string;
  code: string;
  aiCompletionTime: number;
  executionTime: number;
}

export interface InstructionResult {
  id: string;
  model: string;
  title: string;
  created_at: Date;
  code: string;
  data: any[];
  explanation: string;
  charts: any[];
}

export enum FEEDBACK_TYPE {
  GENERAL = "GENERAL",
  AI_ACCURACY = "AI_ACCURACY",
  USABILITY = "USABILITY",
  BUG = "BUG",
  FEATURE_REQUEST = "FEATURE_REQUEST"
}

export enum FEEDBACK_RATING {
  GOOD = "GOOD",
  BAD = "BAD",
  NONE = "NONE"
}

export enum InstructionEnhancementSource {
  INSTRUCTION = 'INSTRUCTION',
  WORKSPACE = 'WORKSPACE'
}

export interface RequestInstructionEnhancementDto {
  instructionText: string;
  source: InstructionEnhancementSource;
  workspaceId?: string;
  instructionId?: string;
}


export const getRegions = () => {
  return regions;
}

export const getThemes = () => {
  return themes.sort((a, b) => a.name.localeCompare(b.name));
}

export const getCountries = () => {
  return countries;
}

export type OpenDataProviderInfo = {
  name: string;
  link: string;
  description: string;
  logo: string;
};

export enum AnalysisType {
  BASIC_ANALYSIS = "BASIC_ANALYSIS",
  AI_ANALYSIS = "AI_ANALYSIS",
}

export interface AnalysisIdea {
  id: string;
  title: string;
  description: string;
  instruction: string;
  createdAt: string;
  datasetVersionId: string;
  updatedAt: string;
}

export type Insight = {
  id?: string;
  title: string;
  description: string;
  outline: string;
  resultSummary: string;
  region: string;
  imgUrl: string;
  executionEnvironment: string;
  chartDetails: any;
  location: string;
  code: string;
  analysisType: AnalysisType;
  curated: boolean;
  notes: string;
  datasetVersionId: string;
  createdAt?: Date;
  updatedAt?: Date;
  task: string;
  data: string | null;
};


export interface OpenDataFilterOptions {
  query: string | null;
  themes: string[];
  lastUpdate: string | null;
  providers: string[];
  regions: {
    countries: string[];
    continents: string[];
  };
  timePeriod: {
    from: null | Date;
    to: null | Date;
  };
}

export enum NOTIFICATION_EVENT_TYPE {
  UPDATED_DATASET = 'UPDATED_DATASET',
}

export enum NOTIFICATION_CHANNEL_TYPE {
  EMAIL = "EMAIL",
}

export interface NotificationChannel {
  id: string;
  settings: Record<string, any>
  type: NOTIFICATION_CHANNEL_TYPE,
}

export interface NotificationPreference {
  id: string;
  user: string;
  event: NOTIFICATION_EVENT_TYPE,
  channels: NotificationChannel[]
  details: Record<string, any>
}

export interface Product {
  id: string;
  enabled: boolean;
  prices: Record<string, { price: number, priceId: string }>;
  name: string;
  description: string;
  features: string[];
}

export interface SearchRequest {
  id: string;
  user: string;
  query: string | null;
  providers: string[];
  countries: string[];
  continents: string[];
  timePeriodFrom: string | null;
  timePeriodTo: string | null;
  themes: string[];
}

export enum WorkspaceShareType {
  PUBLIC = 'PUBLIC',
  SELECTED_MEMBERS = 'SELECTED_MEMBERS',
}

export interface WorkspaceShare {
  id: string,
  type: WorkspaceShareType,
}

// Visualization
export interface ChartProperty {
  id: string,
  key: string,
  value: string,
}

export interface CreateChartDto {
  instructionResultId: string,
  type: CHART_TYPE,
  properties: Array<Partial<ChartProperty>>
}

export interface UpdateChartDto {
  type: CHART_TYPE,
  properties: Array<Partial<ChartProperty>>
}

export enum CHART_TYPE {
  LINE = 'line',
  BAR = 'bar',
  PIE = 'pie',
  MAP = 'map',
  SCATTER = 'scatter',
  TREE_MAP = 'tree map',
}

export enum CHUNK_TYPE {
  ACTION = 'action',
  VISUALIZATION = 'visualization',
  TABLE = 'table',
  TEXT = 'text',
}

export interface ChartSubTypeOption {
  imgUrl: string;
  alt: string;
  tooltip: string;
  subType: string;
}

export enum CHART_LEGEND_SYMBOL_TYPE {
  CIRCLE = 'circle',
  RECT = 'rect',
  ROUND_RECT = 'roundRect',
  TRIANGLE = 'triangle',
  DIAMOND = 'diamond',
  PIN = 'pin',
  ARROW = 'arrow',
  NONE = 'none',
}

export enum CHART_LEGEND_ORIENTATION_TYPE {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export interface HealthCheckResponse {
  status: string;
  service: string;
  version: string;
  dependencies: Record<string, 'ok' | 'down'>;
}


export enum MessageSender {
  AI = 'AI',
  USER = 'USER',
  TOOL = 'TOOL',
}
export enum MessageOutputType {
  TEXT = 'TEXT',
  TOOL_CALL = 'TOOL_CALL',
  VISUALIZATION = 'VISUALIZATION',
  TABLE = 'TABLE',
  ERROR = 'ERROR',
}


export enum ToolCallOutputType {
  DF = "DF",
  OTHER = "OTHER",
  VISUALIZATION = "VISUALIZATION",
}

export interface ToolCall {
  id: string,
  name: string,
  arguments: any,
  loading: boolean,
  updatedAt: Date,
  output?: {
    type: ToolCallOutputType,
    content: string,
    error: boolean,
    reasoning: string | null,
  }
}

export interface KeyValueOutput {
  key: string;
  value: string;
  dataSourceId: string;
}

export interface TransformModel {
  filterModel: Record<string, { values: string[], filterType: string }>
  columnModel: {
    colId: string;
    width: number;
    hide: boolean;
    pinned: null | string;
    sort: null | string;
    sortIndex: null | number;
    aggFunc: null | string;
    rowGroup: boolean;
    rowGroupIndex: null | number;
    pivot: boolean;
    pivotIndex: null | number;
    flex: null | string;
  }[];
  pivotMode: boolean;
  sortModel: {
    colId: string;
    sort: string;
  }[];
  rowGroupCols: {
    field: string;
  }[];
  valueCols: {
    field: string;
    aggFunc: string;
  }[];
  pivotCols: any[]; // Change this if you know the specific structure
}


export interface TableOutput {
  dataSource: {
    id: string;
    name: string;
    modified: boolean;
    modifiedOptions?: TransformModel;
    Dataset?: {
      datasetId: string;
      datasetVersionId: string;
    }
    WorkspaceId: string;
    columns: Partial<Column>[];
    analysisName: string;
    code?: string;
  },
  visualization?: LegacyVisualizationDto
  urls: {
    original: string;
    modified: string | null;
  },
  fromToolCall: string | null;
}

export interface Variable {
  code: string;
  type: DATA_SOURCE_TYPE,
  content: any,
}

export interface LegacyMessageDto {
  id: string;
  sender: MessageSender;
  contentUpdatedAt: Date;
  continueOnConfig: { id: string, type: `chart` | `table` } | null;
  error: boolean;
  loading: boolean;
  createdAt: Date;
  updatedAt: Date;
  isWritingTool: string[] | null;
  content: string | null,
  toolCalls?: ToolCall[],
  tables?: TableOutput[],
  keyValues?: KeyValueOutput[],
  visualizations?: LegacyVisualizationDto[],
  variables?: Variable[],
}

export interface LegacyVisualizationDto {
  id: string,
  version: string,
  columns?: Partial<Column>[]
  data?: string;
  updatedAt: Date;
  fromToolCall: string;
}

export interface MessageChunkDto {
  id: string;
  content: string;
  questions: any[];
  visualizations: VisualizationDto[];
  tables: any[];
  type: string;
  children: MessageChunkDto[];
  actionType: string;
  referencedSources: MessageChunkReferencedSource[];
  
}

export interface MessageChunkReferencedSource {
  type: SourceType,
  value: string,
  title: string,
}

export interface MessageDto {
  id: string;
  // todo: remove content
  content: string;
  chunks: MessageChunkDto[];
  sender: MessageSender;
  tables: any[];
  visualizations: VisualizationDto[];
  images: string[];
  agents: string[];
}

export interface VisualizationDto {
  id: string;
  versionId: string;
  visualizationId: string;
  table: string;
}

export interface ChartPalette {
  id: string;
  name: string;
  seriesColors: string[];
  mapColors: string[];
  textColor: string;
  backgroundColor: string;
  createdAt: string; // Ideally should be Date, but using string to match the format
  organization: string | null;
  font: string;
  xAxis: AxisOptions;
  yAxis: AxisOptions;
  fontOptions: FontOptions;
  lineOptions: LineOptions;
  barOptions: BarOptions;
}

interface BarOptions {
  gap: number;
  categoryGap: number;
  width: number;
  borderRadius: number;
}

interface AxisOptions {
  color: string | null;
  fontSize: number | null;
  fontFamily: string;
}

interface FontOptions {
  fontSize: number;
  fontColor: string | null;
  fontFamily: string;
}

interface LineOptions {
  type: 'solid' | 'dashed' | 'dotted';
  color: string | null;
  width: number;
}

export interface LineChart {
  id: string;
  sort: any;
  title: string;
  xAxis: string;
  yAxis: string[];
  yAxisType: string;
  xAxisType: string;
  byline: string;
  source: string;
  legend: boolean;
  palette: ChartPalette;
  subType: string;
  subTitle: string;
  legendSymbol: CHART_LEGEND_SYMBOL_TYPE;
  legendPosition: ChartLegendPosition;
  legendOrientation: CHART_LEGEND_ORIENTATION_TYPE;
  legendDirection: string;
  legendColumnsMap: Record<string, string>;
  VisualizationId: string;
}

export interface ChartLegendPosition {
  label: string;
  value: {
    top?: number | string;
    left?: number | string;
    right?: number | string;
    bottom?: number | string;
  }
}

export interface ReportDto {
  reports: Report[];
  folders: ReportFolderDto[];
}
export interface ReportFolderDto {
  folder: ReportFolder;
  reports: Report[];
  children: ReportFolderDto[];
}

export interface ReportFolder {
  id: string;
  name: string;
  color: string;
  ReportFolderId?: string | null;
  itemCount?: number;
  createdAt: Date | string;
  updatedAt: Date | string;
}
export enum ReportType {
  POST = 'post',
  DOC = 'doc',
  PRESENTATION = 'presentation',
}

export interface Report {
  id: string;
  name: string;
  imageData: string;
  type: ReportType;
  createdAt: string;
  lastAccessedAt: string | null;
  data: string;
}

interface DataQualityDetails {
  columns: string[];
  description: string;
}

export interface DataQualityScores {
  column_homogeneity_score: number;
  column_name_readability_score: number;
  constant_columns_score: number;
  data_type_consistency_score: number;
  data_type_mismatch_score: number;
  date_format_score: number;
  duplicate_rows_score: number;
  empty_columns_score: number;
  high_zeros_proportion_score: number;
  missing_values_score: number;
  numeric_format_score: number;
  outliers_score: number;
  text_data_quality_score: number;
  timestamp_consistency_score: number;
  clear_date_format_score: number;
}

export interface DataQualityReport {
  details: {
    constant_columns_score: DataQualityDetails;
    text_data_quality_score: DataQualityDetails;
    unique_identifier_score: DataQualityDetails;
  };
  overall_score: number;
  scores: DataQualityScores;
}

export interface ChatWorkspaceVersion {
  id: string;
  WorkspaceId: string;
  ready: boolean;
  datasetsToUpdate: string[];
  createdAt: string;
  updatedAt: string;
  notes: string;
}


export interface ItemExplorerItem {
  id: string;
  name: string;
  createdAt: Date | string;
  updatedAt: Date | string;
  parentId?: string | null;
  [key: string]: any;
}

export interface ItemExplorerFolder {
  id: string;
  name: string;
  color: string;
  itemCount?: number;
  createdAt: Date | string;
  updatedAt: Date | string;
  parentId?: string | null;
}

export interface ItemExplorerFolderDto {
  folder: ItemExplorerFolder;
  items: ItemExplorerItem[];
}


export interface PostgresCredentials {
  id: string;
  database: string;
  schema: string;
  username: string;
  table?: string | null;
  passwordRef?: string | null;
  password?: string | null;
  host: string;
  port: number;
  version: string;
}
export enum Tools {
  EXECUTE_PYTHON = 'execute_python',
  CREATE_LINE_CHART = 'create_line_chart',
  CREATE_PIE_CHART = 'create_pie_chart',
  CREATE_BAR_CHART = 'create_bar_chart',
  CREATE_MAP_CHART = 'create_map_chart',
  CREATE_SCATTER_CHART = 'create_scatter_chart',
  CREATE_TREE_CHART = 'create_tree_chart',
  GET_COLUMNS_METADATA = 'get_columns_metadata',
  KEY_VALUE_OUTPUT = 'key_value_output',
  ANALYZE_DATA = 'analyze_data',
}


export interface DocumentDto {
  documents: Document[];
  folders: DocumentFolderDto[];
}
export interface DocumentFolderDto {
  folder: DocumentFolder;
  documents: Document[];
  children: DocumentFolderDto[];
}

export enum MessageChunkActionType {
  ANALYZING = 'analyzing',
  ANALYSIS_COMPLETED = 'analysis_completed',
  RESEARCHING = 'researching',
  RESEARCH_COMPLETED = 'research_completed',
  ASK_QUESTION = 'ask_question',
  CREATING_CHART = 'creating_chart',
  CHART_CREATED = 'chart_created',
  FETCHING_DOCUMENT_CONTENT = 'fetching_document_content',
  DOCUMENT_CONTENT_FETCHED = 'document_content_fetched',
  ERROR = 'error',
  SEARCH_KNOWLEDGE = 'search_knowledge',
  KNOWLEDGE_SEARCHED = 'knowledge_searched',
}

export interface DocumentFolder {
  id: string;
  name: string;
  color: string;
  DocumentFolderId?: string | null;
  itemCount?: number;
  createdAt: Date | string;
  updatedAt: Date | string;
}

export enum DocumentType {
  PAGE_A4 = 'PAGE_A4',
  SLIDES_16_9 = 'SLIDES_16_9',
  SM_POST_SQUARE = 'SM_POST_SQUARE',
}

export enum DocumentShareType {
  MEMBERS = 'MEMBERS',
  PUBLIC = 'PUBLIC',
}

export enum MemberRole {
  OWNER = 'OWNER',
  EDITOR = 'EDITOR',
  VIEWER = 'VIEWER',
}

export interface Document {
  id: string;
  name: string;
  type: DocumentType;
  owner: string;
  lastAccessedAt: Date;
  shared: DocumentShareType;
  language: string;
  analysisStyle: string;
  preview: string;
  tone: string;
  outputFormat: string;
  context: string;
  updateAvailable: boolean;
  DocumentFolderId?: string | null;
}

export interface ModalElement {
  show: (...args: any[]) => void;
  close: () => void;
}

export interface ContextMenuElement {
  show: (position: { top: number; left: number }) => void;
  close: () => void;
}